<template>
  <layout>
    <template slot="main">
      <v-container>
        <v-row class="mt-5" justify="center">
          <v-col cols="12" md="5">
            <v-card @click="gorevlerimClick()">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h3">{{
                    this.gorevlerimCount[0]?.count
                  }}</v-card-title>
                  <v-card-subtitle>Görevlerim</v-card-subtitle>
                </div>
                <v-avatar size="125" tile>
                  <v-img src="../assets/gorevlerim.jpg"></v-img>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="5">
            <v-card @click="tumGorevlerClick()">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h3">{{
                    this.gorevlerCount[0]?.count
                  }}</v-card-title>
                  <v-card-subtitle>Tüm Görevler</v-card-subtitle>
                </div>
                <v-avatar size="125" tile>
                  <v-img src="../assets/tumgorevler.jpg"></v-img>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="5" v-if="kasa_bakiyeleri[0]">
            <v-card>
              <v-card-title class="text-h5">Kasalar</v-card-title>
              <div>
                <apexchart
                  type="donut"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import { gorevlerCount, gorevlerimCount } from "../query/anasayfa";

export default {
  components: { Layout },

  data() {
    return {
      gorevlerCount: [],
      gorevlerimCount: [],
      gelen_kasa_bilgisi: [],
      kasa_bakiyeleri: [],
      kasa_adlari: [],
      series: [],

      chartOptions: {
        labels: [],
        dataLabels: {
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex];
          },
        },
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 500,
            options: {
              plotOptions: {
                pie: {
                  size: 900,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  async mounted() {
    this.gorevlerCount = await gorevlerCount();
    this.gorevlerimCount = await gorevlerimCount();

    //console.log(this.gelen_kasa_bilgisi)
    this.deneme();
  },
  methods: {
    gorevlerimClick() {
      this.$router.push({
        name: "GorevlerList",
        params: {
          tumGorevler: false,
        },
      });
    },
    tumGorevlerClick() {
      this.$router.push({
        name: "GorevlerList",
        params: {
          tumGorevler: true,
        },
      });
    },
    gelirEkle() {},
    giderEkle() {},
    deneme() {
      this.gelen_kasa_bilgisi = this.gelen_kasa_bilgisi.filter((value) => {
        this.kasa_adlari.push(value.ad);
        this.kasa_bakiyeleri.push(Number(value.bakiye));
      });
      this.chartOptions = {
        labels: this.kasa_adlari,
      };
      console.log(this.kasa_bakiyeleri);
      this.series = this.kasa_bakiyeleri;
    },
  },
};
</script>
