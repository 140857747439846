import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const gorevlerimCount = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/gorevlerim_count`,
  });
};
const gorevlerCount = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/gorevler_count`,
  });
};

export { gorevlerimCount, gorevlerCount };
