<template>
  <v-navigation-drawer v-model="$store.state.navDrawer" app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> Ofis </v-list-item-title>
        <v-list-item-subtitle> NetKod </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item :to="item.to" v-for="item in items" :key="item.title" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: { model: null },
  data() {
    return {
      internalDrawer: null,
      items: [
        {
          title: "AnaSayfa",
          icon: "mdi-image",
          to: { name: "AnaSayfa" },
        },
        {
          title: "Firmalar",
          icon: "mdi-view-dashboard",
          to: { name: "FirmalarList" },
        },
        { title: "Görevler", icon: "mdi-image", to: { name: "GorevlerList" } },
        {
          title: "Kullanıcılar",
          icon: "mdi-image",
          to: { name: "KullanicilarList" },
        },
        {
          title: "Ürünler",
          icon: "mdi-image",
          to: { name: "UrunlerList" },
        },
        // {
        //   title: "Kasalar",
        //   icon: "mdi-image",
        //   to: { name: "KasalarList" },
        // },
        // {
        //   title: "Bankalar",
        //   icon: "mdi-image",
        //   to: { name: "BankalarList" },
        // },
        // {
        //   title: "Kredi Kartları",
        //   icon: "mdi-image",
        //   to: { name: "KartlarList" },
        // },
        // {
        //   title: "Belge İşlemleri",
        //   icon: "mdi-image",
        //   to: { name: "BelgeMenu" },
        // },
        // {
        //   title: "İşlem Tipleri",
        //   icon: "mdi-image",
        //   to: { name: "IslemTipleriList" },
        // },
        // {
        //   title: "Gelirler",
        //   icon: "mdi-image",
        //   to: { name: "GelirList" },
        // },
        // {
        //   title: "Giderler",
        //   icon: "mdi-image",
        //   to: { name: "GiderList" },
        // },
        {
          title: "Pos Lisansları",
          icon: "mdi-image",
          to: { name: "PosList" },
        },
        {
          title: "Eski Key Oluştur",
          icon: "mdi-image",
          to: { name: "EskiKeyOlustur" },
        },
        {
          title: "Yeni Key Oluştur",
          icon: "mdi-image",
          to: { name: "YeniKeyOlustur" },
        },
      ],
    };
  },
  watch: {
    internalDrawer(val) {
      this.$emit("drawerChange", val);
    },
    model() {
      this.internalDrawer = this.model;
    },
  },
};
</script>
