import Vue from "vue";
import VueRouter from "vue-router";
import AnaSayfa from "../views/AnaSayfa.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    redirect: "/AnaSayfa",
  },
  {
    path: "/AnaSayfa",
    name: "AnaSayfa",
    component: AnaSayfa,
  },
  {
    path: "/FirmalarList",
    name: "FirmalarList",
    component: () => import("../views/Firmalar/List.vue"),
  },
  {
    path: "/GorevlerList",
    name: "GorevlerList",
    component: () => import("../views/Gorevler/List.vue"),
  },
  {
    path: "/Gorevler/Hareketler/show",
    name: "Hareketler",
    component: () => import("../views/Gorevler/Hareketler/Show.vue"),
  },
  {
    path: "/Rapor",
    name: "Rapor",
    component: () => import("../views/Gorevler/Rapor.vue"),
  },
  {
    path: "/Kullanicilar",
    name: "KullanicilarList",
    component: () => import("../views/Kullanicilar/List.vue"),
  },
  {
    path: "/Urunler",
    name: "UrunlerList",
    component: () => import("../views/Urunler/List.vue"),
  },
  {
    path: "/PosList",
    name: "PosList",
    component: () => import("../views/Pos/List.vue"),
  },
  {
    path: "/EskiKeyOlustur",
    name: "EskiKeyOlustur",
    component: () => import("../views/Pos/EskiKeyOlusturma.vue"),
  },
  {
    path: "/YeniKeyOlustur",
    name: "YeniKeyOlustur",
    component: () => import("../views/Pos/YeniKeyOlustur.vue"),
  },
  {
    path: "/OturumAc",
    name: "OturumAc",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const durum = localStorage.getItem("token") ? true : false;
  if (to.name !== "OturumAc" && !durum) {
    console.log("zey");
    // next({ name: "OturumAc" });
  } else {
    return next();
  }
  console.log("local", durum, to, from, next);
});

export default router;
