<template>
  <v-app-bar app color="primary" dark>
    <v-app-bar-nav-icon
      @click="$store.state.navDrawer = !$store.state.navDrawer"
    ></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-btn target="_blank" text @click="oturumSonlandir()">
      <span class="mr-2">Çıkış</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    oturumSonlandir() {
      this.$router.push({
        name: "OturumAc",
      });
    },
  },
};
</script>

<style></style>
